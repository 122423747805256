import React from 'react';

import { Banner, PicnicCss } from '@attentive/picnic';

export const SegmentDataAvailabilityBanner = ({ css }: { css?: PicnicCss }) => (
  <Banner css={{ width: '100%', ...css }}>
    <Banner.Text>
      Segment performance data is not available for campaigns that were delivered prior to
      2024-08-01.
    </Banner.Text>
  </Banner>
);
